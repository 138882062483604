export const storage = function (path) {
    return `${import.meta.env.VITE_APP_URL}/storage/${path}`
}

export const copyTextToClipboard = async function (text) {
    return navigator.clipboard.writeText(text)
}

export const toggleCollapse = function (id) {
    const modal = document.getElementById(id)
    if (modal) {
        if (modal.classList.contains('hidden')) {
            modal.classList.add('flex')
            modal.classList.remove('hidden')
        } else {
            modal.classList.add('hidden')
            modal.classList.remove('flex')
        }
    }
}

export const showModal = function (id) {
    const modal = document.getElementById(id)
    if (modal && modal.classList.contains('hidden')) {
        modal.classList.add('flex')
        modal.classList.remove('hidden')
    }
}

export const closeModal = function (id) {
    const modal = document.getElementById(id)
    if (modal && modal.classList.contains('flex')) {
        modal.classList.add('hidden')
        modal.classList.remove('flex')
    }
}

export const closeAllModals = function () {
    const modals = document.getElementsByClassName('modal')
    for (let i = 0; i < modals.length; i++) {
        let modal = modals.item(i)
        modal.classList.add('hidden')
        modal.classList.remove('flex')
    }
}

export const openDropdownList = function (id) {
    const dropdownList = document.getElementById(id)
    if(dropdownList){
        dropdownList.classList.add('flex')
        dropdownList.classList.remove('hidden')
    }
}

export const closeDropdownList = function (id) {
    const dropdownList = document.getElementById(id)
    if(dropdownList) {
        dropdownList.classList.add('hidden')
        dropdownList.classList.remove('flex')
    }
}

export const closeAllDropdownLists = function () {
    const dropdownLists = Array.from(document.getElementsByClassName('dropdown-list'))
    dropdownLists.forEach((list) => {
        list.classList.add('hidden')
        list.classList.remove('flex')
    })
}

export const openTab = function (panelId, tabId) {
    closeAllTabs(panelId)
    const tab = document.getElementById(tabId)
    tab.classList.add('flex')
    tab.classList.remove('hidden')
}

export const closeTab = function (tabId) {
    const tab = document.getElementById(tabId)
    tab.classList.add('hidden')
    tab.classList.remove('flex')
}

export const closeAllTabs = function (panelId) {
    const tabs = Array.from(document.getElementById(panelId).children)
    tabs.forEach((tab) => {
        if (tab.role === 'tabpanel') {
            tab.classList.add('hidden')
            tab.classList.remove('flex')
        }
    })
}

export const validateIdNumber = function (idNumber) {
    return idNumber.replace(/\D/g, '')
}

export const validateTaxNumber = function (taxNumber) {
    const number = taxNumber.replace(/\D/g, '')
    if (number.length <= 2) {
        return number
    }
    const prefix = number.substring(0, 2)
    if (number.length === 3) {
        const middle = number.substring(2, number.length)
        return `${prefix}-${middle}`
    }
    const middle = number.substring(2, number.length - 1)
    const suffix = number.substring(number.length - 1, number.length)
    return `${prefix}-${middle}-${suffix}`
}

/**
 * Calculate coordinate string
 * @param {number} lat latitude
 * @param {number} long longitude
 * @param {function} translate translation function
 * @return {string} the coordinates in text format like "LATº {N|S}, LONGº {E|W}"
 */
export const coordinates = function (lat, long, translate) {
    let res = Math.abs(lat).toString()
    if (lat > 0) {
        res += `º ${translate('general.coord.n')}`
    } else if (lat < 0) {
        res += `º ${translate('general.coord.s')}`
    }
    res += `, ${Math.abs(long).toString()}`
    if (long > 0) {
        res += `º ${translate('general.coord.e')}`
    } else if (long < 0) {
        res += `º ${translate('general.coord.w')}`
    }
    return res
}